var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-count",attrs:{"data-v-19da6b64":""}},[_c('div',{staticClass:"wrapper-count",attrs:{"data-v-19da6b64":""}},[_c('span',{staticClass:"closeBtn iconfont icon-guanbi2",attrs:{"data-v-19da6b64":""},on:{"click":function($event){_vm.$store.state.loginDilaog = false}}}),_c('div',{staticClass:"wrapper",attrs:{"data-v-19da6b64":""}},[_c('div',{staticClass:"title",attrs:{"data-v-19da6b64":""}},[_c('span',{staticClass:"item_title font_red",attrs:{"data-v-19da6b64":""}},[_vm._v(_vm._s(_vm.$t("text19")))])]),_c('div',{staticClass:"item phone acea-row row-middle",attrs:{"data-v-19da6b64":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.account),expression:"account"}],attrs:{"data-v-19da6b64":"","type":"text","placeholder":_vm.$t('login.account')},domProps:{"value":(_vm.account)},on:{"input":function($event){if($event.target.composing)return;_vm.account=$event.target.value}}})]),_c('div',{staticClass:"item pwd",attrs:{"data-v-19da6b64":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"data-v-19da6b64":"","type":"password","placeholder":_vm.$t('login.password')},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}})]),_c('div',{staticClass:"checkbox-wrapper item_protocol",attrs:{"data-v-19da6b64":""}},[_c('label',{staticClass:"well-check",attrs:{"data-v-19da6b64":""}},[_c('input',{attrs:{"data-v-19da6b64":"","type":"checkbox","name":"","value":""}}),_c('i',{staticClass:"icon",attrs:{"data-v-19da6b64":""}}),_c('span',{attrs:{"data-v-19da6b64":""}},[_vm._v(_vm._s(_vm.$t("register.tip")))])]),_c('a',{staticClass:"show_protocol",attrs:{"data-v-19da6b64":"","target":"_blank"},on:{"click":function($event){_vm.$store.commit('clearDiloag');_vm.$router.push({
            path:'/privacy_agreement',
            query:{
              type:1
            }
          })}}},[_vm._v(_vm._s(_vm.$t("register.yfxy"))+" ")]),_vm._v(" "+_vm._s(_vm.$t("register.and"))+" "),_c('a',{staticClass:"show_protocol",attrs:{"data-v-19da6b64":"","target":"_blank"},on:{"click":function($event){_vm.$store.commit('clearDiloag');_vm.$router.push({
            path:'/privacy_agreement',
            query:{
              type:2
            }
          })}}},[_vm._v(" "+_vm._s(_vm.$t("register.yszc"))+" ")])]),_c('div',{staticClass:"signIn bg-color",attrs:{"data-v-19da6b64":""},on:{"click":_vm.login}},[_vm._v(_vm._s(_vm.$t("login.login")))]),_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"space-between","padding":"0 45px"}},[_c('div',{staticClass:"fastLogin",attrs:{"data-v-19da6b64":""}},[_vm._v(" "+_vm._s(_vm.$t("text20"))+" "),_c('span',{staticClass:"font-color",attrs:{"data-v-19da6b64":""},on:{"click":function($event){_vm.$store.state.loginDilaog=false;_vm.$store.state.registeredDialog=true;}}},[_vm._v(_vm._s(_vm.$t("register.register")))])]),_c('div',{staticClass:"fastLogin",attrs:{"data-v-19da6b64":""},on:{"click":function($event){_vm.$store.state.loginDilaog=false;_vm.$store.state.forgetDialog=true;}}},[_c('span',{staticClass:"font-color"},[_vm._v(_vm._s(_vm.$t("login.forgot")))])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }