<template>
  <div id="__nuxt" class="demo-image__lazy">
    <div id="__layout">
      <div>
        <div data-v-3df7a256="">
          <div data-v-3df7a256="" class="index_banner">
            <img data-v-3df7a256="" src="https://www.magasings.com/" />
          </div>
        </div>
        <HeaderTop></HeaderTop>

        <div data-v-020511ba="">
          <div data-v-020511ba="" class="index wrapper_1200">
            <div data-v-020511ba="" class="categoryMain">
              <div data-v-020511ba="" class="wrapper_1200">
                <div
                  data-v-020511ba=""
                  class="list wrapper_1200 acea-row row-middle"
                >
                  <div data-v-020511ba="" class="categoryBtn">{{ $t("classify.classify") }}</div>
                  <a
                    data-v-020511ba=""
                    class="item"
                    @click="$router.push('/goods_cate')"
                    >{{ $t("text4") }} </a
                  ><a
                    data-v-020511ba=""
                    class="item"
                    @click="$router.push('/shop_cate')"
                    >{{ $t("text5") }}
                  </a>
                </div>
              </div>
            </div>
            <div data-v-020511ba="" class="wrapper">
              <div data-v-020511ba="" class="slider-banner banner">
                <div
                  data-v-020511ba=""
                  v-loading="loading"
                  class="swiper-container swiper-container-initialized swiper-container-horizontal"
                >
                  <div
                    data-v-020511ba=""
                    class="swiper-wrapper"
                    style="
                      transform: translate3d(-3600px, 0px, 0px);
                      transition-duration: 1000ms;
                    "
                  >
                    <div
                      data-v-020511ba=""
                      v-for="(item, index) in banner_list"
                      :key="index"
                      class="swiper-slide"
                      style="width: 1200px"
                    >
                      <img data-v-020511ba="" :src="item.image" />
                    </div>
                  </div>
                  <!-- <div data-v-020511ba="" slot="pagination"
											class="swiper-pagination paginationBanner swiper-pagination-clickable swiper-pagination-bullets">
											<span class="swiper-pagination-bullet swiper-pagination-bullet-active"
												tabindex="0" role="button" aria-label="Go to slide 1"></span><span
												class="swiper-pagination-bullet" tabindex="0" role="button"
												aria-label="Go to slide 2"></span></div> -->
                  <!-- <div data-v-020511ba="" slot="pagination" class="swiper-button-prev"
											tabindex="0" role="button" aria-label="Previous slide"></div> -->
                  <!-- <div data-v-020511ba="" slot="pagination" class="swiper-button-next"
											tabindex="0" role="button" aria-label="Next slide"></div><span
											class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
-->
                </div>
              </div>
              <div data-v-020511ba="" class="category acea-row row-middle">
                <div data-v-020511ba="" class="sort sort-scroll">
                  <div
                    data-v-020511ba=""
                    @click="goods_cate(item)"
                    v-for="(item, index) in init_data?.modulesData
                      ?.categoryModules"
                    :key="index"
                    class="item acea-row row-between-wrapper"
                  >
                    <div data-v-020511ba="" class="name line1">
                      {{ item.name }}
                    </div>
                    <div data-v-020511ba="" class="iconfont icon-you"></div>
                  </div>
                </div>
              </div>
            </div>
            <div data-v-020511ba="" class="seckill acea-row row-middle"></div>
            <div data-v-020511ba="" id="page2" class="boutique">
              <div
                data-v-020511ba=""
                class="publicTitle acea-row row-between-wrapper"
              >
                <div data-v-020511ba="" class="title acea-row row-middle"></div>
              </div>
            </div>
            <div data-v-020511ba="" id="page3" class="newGoods">
              <div
                data-v-020511ba=""
                class="publicTitle acea-row row-between-wrapper"
              >
                <div data-v-020511ba="" class="title acea-row row-middle">
                  <div data-v-020511ba="" class="pictrue">{{ $t('index.Recommended') }}</div>
                </div>

                <a
                  data-v-020511ba=""
                  @click="getindex_goods_list()"
                  class="more acea-row row-center-wrapper"
                >
                {{ $t("replace") }}
                  <el-icon class="el-icon-refresh"></el-icon>
                </a>
              </div>
              <div
                data-v-020511ba=""
                v-loading="loading"
                class="list acea-row row-middle"
                style="display: flex"
              >
                <div
                  @click="
                    $router.push({
                      path: '/productDetails',
                      query: {
                        id: item.id,
                      },
                    })
                  "
                  v-for="(item, index) in goods_list.list"
                  :key="index"
                  data-v-020511ba=""
                  class="item"
                >
                  <div data-v-020511ba="" class="pictrue">
                    <el-image
                      :src="item.goods.image"
                      style="width: 192px; height: 192px"
                      :fit="'scale-down'"
                    ></el-image>
                    <!-- <img data-v-020511ba=""
											:src="item.goods.image"> -->
                  </div>
                  <div data-v-020511ba="" class="name line1">
                    {{ item.goods.title }}
                  </div>
                  <div data-v-020511ba="" class="money line1">
                    <span data-v-020511ba="" class="label">{{ $t("text6") }}</span>
                    <span data-v-020511ba="" class="font-color"
                      >{{ item.goods.hb_unit }}
                      {{ item.goods.price.toFixed(2) }}</span
                    >
                  </div>
                  <div data-v-020511ba="" class="stock">
                    {{ $t("product.goods.sales") }} {{ item.sales }}
                  </div>
                </div>
              </div>
            </div>

            <div data-v-020511ba="" id="page3" class="newGoods">
              <div
                data-v-020511ba=""
                class="publicTitle acea-row row-between-wrapper"
              >
                <div data-v-020511ba="" class="title acea-row row-middle">
                  <div data-v-020511ba="" class="pictrue">{{ $t("index.hot") }}</div>
                </div>
                <a
                  data-v-020511ba=""
                  @click="getindex_goods_list()"
                  class="more acea-row row-center-wrapper"
                >
                  {{ $t("replace") }}
                  <el-icon class="el-icon-refresh"></el-icon>
                </a>
                <!-- <a data-v-020511ba="" href="https://www.magasings.com/goods_list?type=new"
										class="more acea-row row-center-wrapper">
										更多<span data-v-020511ba="" class="iconfont icon-you"></span></a> -->
              </div>
              <div
                data-v-020511ba=""
                v-loading="loading"
                class="list acea-row row-middle"
                style="display: flex"
              >
                <div
                  v-for="(item, index) in goods_list.list2"
                  :key="index"
                  data-v-020511ba=""
                  class="item"
                >
                  <div data-v-020511ba="" class="pictrue">
                    <el-image
                      :src="item.goods.image"
                      style="width: 192px; height: 192px"
                      :fit="'scale-down'"
                    ></el-image>
                  </div>
                  <div data-v-020511ba="" class="name line2">
                    {{ item.goods.title }}
                  </div>
                  <div data-v-020511ba="" class="money line1">
                    <span
                      data-v-020511ba=""
                      style="margin-left: 0"
                      class="font-color"
                      >{{ item.goods.hb_unit }}
                      {{ item.goods.price.toFixed(2) }}</span
                    >
                  </div>
                  <!-- <div data-v-020511ba="" class="stock">销量 {{item.sales}}</div> -->
                </div>
              </div>
            </div>

            <div data-v-020511ba="" id="page3" class="newGoods">
              <div
                data-v-020511ba=""
                class="publicTitle acea-row row-between-wrapper"
              >
                <div data-v-020511ba="" class="title acea-row row-middle">
                  <div data-v-020511ba="" class="pictrue">{{ $t("goodList") }}</div>
                </div>
                <a
                  data-v-020511ba=""
                  @click="getgoods_list()"
                  class="more acea-row row-center-wrapper"
                >
                {{ $t("replace") }}
                  <el-icon class="el-icon-refresh"></el-icon>
                </a>
              </div>
              <div
                v-loading="loading1"
                data-v-020511ba=""
                class="list acea-row row-middle"
                style="display: flex"
              >
                <div
                  @click="
                    $router.push({
                      path: '/productDetails',
                      query: {
                        id: item.id,
                      },
                    })
                  "
                  v-for="(item, index) in goods_list2"
                  :key="index"
                  data-v-020511ba=""
                  class="item"
                >
                  <div data-v-020511ba="" class="pictrue">
                    <el-image
                      :src="item.goods.image"
                      style="width: 192px; height: 192px"
                      :fit="'scale-down'"
                    ></el-image>
                  </div>
                  <div data-v-020511ba="" class="name line2">
                    {{ item.goods.title }}
                  </div>
                  <div data-v-020511ba="" class="money line1">
                    <span
                      data-v-020511ba=""
                      style="margin-left: 0"
                      class="font-color"
                      >{{ item.goods.hb_unit }}
                      {{ item.goods.price.toFixed(2) }}</span
                    >
                  </div>
                  <!-- <div data-v-020511ba="" class="stock">销量 {{item.sales}}</div> -->
                </div>
              </div>
            </div>

            <div data-v-020511ba="" id=" page4" class="classify">
              <div data-v-020511ba="" class="classify-title">
                <span data-v-020511ba="">{{ $t("tabBar.classify") }}</span>
              </div>
            </div>

            <div
              v-for="(item1, index1) in init_data?.modulesData?.categoryModules"
              :key="index1"
              data-v-020511ba=""
              :id="'page3 category' + item1.id"
              class="newGoods"
            >
              <div
                data-v-020511ba=""
                class="publicTitle acea-row row-between-wrapper"
              >
                <div data-v-020511ba="" class="title acea-row row-middle">
                  <div data-v-020511ba="" class="pictrue">{{ item1.name }}</div>
                </div>
                <a
                  data-v-020511ba=""
                  @click="
                    $router.push({
                      path: '/goods_cate',
                      query: {
                        category_id: item1.id,
                        name: item1.name,
                      },
                    })
                  "
                  class="more acea-row row-center-wrapper"
                >
                  {{ $t("text7") }}
                  <el-icon class="el-icon-arrow-right"></el-icon>
                </a>
              </div>

              <div
                v-loading="loading1"
                data-v-020511ba=""
                class="list acea-row row-middle"
                style="display: flex"
              >
                <div
                  @click="
                    $router.push({
                      path: '/productDetails',
                      query: {
                        id: item.id,
                      },
                    })
                  "
                  v-for="(item, index) in item1?.data"
                  :key="index"
                  data-v-020511ba=""
                  class="item"
                >
                  <div data-v-020511ba="" class="pictrue">
                    <el-image
                      :src="item.goods.image"
                      style="width: 192px; height: 192px"
                      :fit="'scale-down'"
                      lazy
                    ></el-image>
                  </div>
                  <div data-v-020511ba="" class="name line2">
                    {{ item.goods.title }}
                  </div>
                  <div data-v-020511ba="" class="money line1">
                    <span
                      data-v-020511ba=""
                      style="margin-left: 0"
                      class="font-color"
                      >{{ item.goods.hb_unit }}
                      {{ item.goods.price.toFixed(2) }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BottomFixed></BottomFixed>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderTop from "../components/HeaderTop.vue";
import BottomFixed from "../components/BottomFixed.vue";
import $ from "jquery";
// import Swiper from "swiper";
export default {
  components: {
    HeaderTop,
    BottomFixed,
  },
  data() {
    return {
      goods_list: {},
      goods_list2: [], //推荐
      init_data: {},
      lists_data: [],
      index_data: {},
      banner_list: [],
      loading: true,
      loading1: true,
      app_url: "",
    };
  },
  methods: {
    loadmore() {},
    getRandomChar() {
      const charCodeA = "a".charCodeAt(0);
      const charCodeZ = "z".charCodeAt(0);
      const randomCharCode =
        charCodeA + Math.random() * (charCodeZ - charCodeA);
      return String.fromCharCode(randomCharCode);
    },
    goods_cate(item) {
      this.$router.push({
        path: "/goods_cate",
        query: {
          category_id: item.id,
          name: item.name,
        },
      });
    },

    getindex_goods_list() {
      this.loading = true;
      this.$axios({
        url: "/api/index/index_goods_list",
        method: "POST",
      }).then((res) => {
        console.log(res.data);
        this.goods_list = res.data.data;
        this.loading = false;
      });
    },
    getgoods_list() {
      this.loading1 = true;
      this.$axios({
        url: "/api/index/goods_list",
        method: "POST",
      }).then((res) => {
        console.log(res.data);
        this.goods_list2 = res.data.data;
        this.loading1 = false;
      });
    },
    getinit() {
      this.$axios({
        url: "/api/wanlshop/common/init",
        method: "POST",
      }).then((res) => {
        if (res.data.code == 1) {
          this.init_data = res.data.data;
          // let arr = this.init_data.modulesData.categoryModules;
          // setTimeout(() => {
          // window.addEventListener("scroll", () => {
          //   arr.forEach((item) => {
          //     let ele = document.getElementById("category" + item.id);
          //     const rect = ele.getBoundingClientRect();

          //     console.log(rect, item.id);
          //   });
          // });

          this.init_data.modulesData.categoryModules.forEach((item) => {
            this.$axios({
              url: `/api/wanlshop/product/lists?search=&sort=shop_goods.createtime&order=desc&page=1&filter={%22category_id%22:${item.id},%22shop_goods.price%22:%22,%22}&op={%22category_id%22:%22in%22,%22shop_goods.price%22:%22BETWEEN%22}&type=goods`,
              methods: "GET",
            }).then((res1) => {
              if (res1.data.code == 1) {
                item.data = res1.data.data.data;
                this.$forceUpdate();
              }
            });
          });
          // this.init_data  =res.data.data

          // }, 4000);
        }

        //     var swiper = new Swiper("#swiper-container3", {

        //       slidesPerView: 10,
        //   spaceBetween: 30,
        //   freeMode: true,
        //   autoplay: true,
        //     });
      });
    },

    getlists() {
      this.$axios({
        url: `/api/wanlshop/product/lists?type=shop&search=${this.getRandomChar()}&page=1&sort=sales`,
        method: "GET",
      }).then((res) => {
        console.log(res.data);
        this.lists_data = res.data.data.data;
        // var swiper = new Swiper("#swiper-container2", {  slidesPerView: 3,
        //     spaceBetween: 30,
        //     freeMode: true,
        //     autoplay: true,});
      });
    },

    getindex() {
      this.$axios({
        url: "/api/index/index",
        method: "POST",
      }).then((res) => {
        console.log(res.data);
        this.index_data = res.data.data;
        this.banner_list = res.data.data.banner;
        this.app_url = res.data.data.app_url;
        this.$store.state.app_url = this.app_url;
        window.localStorage.setItem("app_url", this.app_url);

        if (res.data.data.banner) {
          setTimeout(() => {
            new Swiper(".swiper-container", { autoplay: true });
          });
          this.loading = false;
        }
      });
    },

    // follow(item){

    //   console.log(item)
    //   this.$axios({
    //     url:"/api/wanlshop/product/follow",
    //     method:"POST",
    //     data:{
    //       id:item.id,
    //       shop_goods_id:item.goods_id
    //     }
    //   }).then(res => {

    //   })
    // }
  },
  mounted() {
    this.getindex();
    this.getindex_goods_list();
    this.getinit();
    this.getgoods_list();
    // this.getlists()
  },
};
</script>

<style   scoped>
.index .newGoods .list .item ~ .item[data-v-020511ba] {
  margin-left: 0px;
  margin-bottom: 14px;
}

.index .newGoods .list {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-between;
}
.name line2 {
  min-height: 40px;
}
.title .pictrue {
  width: unset !important;
  color: #000;
  font-weight: bold;
}
.more{
  
  width: auto!important;
  padding: 0 20px;
}
</style>
